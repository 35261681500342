import { Link } from "@reach/router";

const addJobIcon = require("../../../image/icon/add-job_ic.svg");
const codingAssessment = require("../../../image/icon/coding_assessment.svg");
const homeIcon = require("../../../image/icon/home_ic.svg");
const teamIcon = require("../../../image/icon/team_ic.svg");
const workflowIcon = require("../../../image/icon/workflow_ic.svg");
const jobsIcon = require("../../../image/icon/jobs_ic.svg");
const fileIcon = require("../../../image/icon/file_ic.svg");
const domainIcon = require("../../../image/icon/domain_ic.svg");
const reviewIcon = require("../../../image/icon/review_ic.svg");
const starIcon = require("../../../image/icon/star_ic.svg");
const mailIcon = require("../../../image/icon/mail.svg");
const offerIcon = require("../../../image/icon/offer.svg");
const interviewsIcon = require("../../../image/icon/Interview.svg");
const backgroundIcon = require("../../../image/icon/background.svg");
const drugScreeningIcon = require("../../../image/icon/Drug.svg");

import { AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

export const CompanySidebar = ({ setActive }) => {
  return (
    <div className="aside__top">
      <div className="aside__top__part">
        <ul className="aside__top__list">
          <li className="aside__top__item">
            <Link
              to="/"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/")}
            >
              <img src={homeIcon} alt="" className="aside__top__icon" />
              <p className="aside__top__text">Home</p>
            </Link>
          </li>
          <li className="aside__top__item">
            <Link
              to="/recruitment/mail-box"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/recruitment/mail-box")}
            >
              <img src={mailIcon} alt="Mail Box" className="aside__top__icon" />
              <p className="aside__top__text">Mail Box</p>
            </Link>
          </li>
          {/* <li className="aside__top__item">
            <Link
              to="/calendar"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/calendar")}
            >
              <img
                src={calendarIcon}
                alt="Calendar"
                className="aside__top__icon"
              />
              <p className="aside__top__text">Calendar</p>
            </Link>
          </li>
          <li className="aside__top__item">
            <Link
              to="/files"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/files")}
            >
              <img src={filesIcon} alt="Files" className="aside__top__icon" />
              <p className="aside__top__text">Files</p>
            </Link>
          </li> */}
        </ul>
      </div>
      <div className="aside__top__part">
        <ul className="aside__top__list">
          <li className="aside__top__item">
            <Link
              to="/recruitment/home"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/recruitment/home")}
            >
              <img src={homeIcon} alt="" className="aside__top__icon" />
              <p className="aside__top__text">Recruitment</p>
            </Link>
          </li>
          <AuthGuard module="job-post" permission="add">
            <li className="aside__top__item">
              <Link
                to="/job/create"
                className="aside__top__link"
                getProps={(props) => setActive(props, "/job/create")}
              >
                <img src={addJobIcon} alt="" className="aside__top__icon" />
                <p className="aside__top__text">To Post Job</p>
              </Link>
            </li>
          </AuthGuard>
          <AuthGuard module="team-members" permission={["view", "add", "edit","delete"]}>
            <li className="aside__top__item">
              <Link
                to="/recruitment/manage-team-members"
                className="aside__top__link"
                getProps={(props) =>
                  setActive(props, "/recruitment/manage-team-members")
                }
              >
                <img src={teamIcon} alt="" className="aside__top__icon" />
                <p className="aside__top__text">Team Members</p>
              </Link>
            </li>
          </AuthGuard>
          <AuthGuard module="job-post" permission={["view", "add", "edit","delete"]}>
            <li className="aside__top__item">
              <Link
                to="/recruitment/position-workflow"
                className="aside__top__link"
                getProps={(props) =>
                  setActive(props, "/recruitment/position-workflow")
                }
              >
                <img src={workflowIcon} alt="" className="aside__top__icon" />
                <p className="aside__top__text">Workflows</p>
              </Link>
            </li>
          </AuthGuard>
          {/* <li className="aside__top__item">
            <Link
              to="/screening-questions"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/screening-questions")}
            >
              <img
                src={screeningQuestionIcon}
                alt=""
                className="aside__top__icon"
              />
              <p className="aside__top__text">Screening questions</p>
            </Link>
          </li> */}
          <AuthGuard module="job-post" permission={["view", "add", "edit","delete"]}>
            <li className="aside__top__item">
              <Link
                to="/recruitment/jobs"
                className="aside__top__link"
                getProps={(props) => setActive(props, "/recruitment/jobs")}
              >
                <img src={jobsIcon} alt="" className="aside__top__icon" />
                <p className="aside__top__text">Jobs</p>
              </Link>
            </li>
          </AuthGuard>
          {/* <li className="aside__top__item">
            <Link
              to="/recruitment/candidate-profile"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/candidates")}
            >
              <img src={candidateIcon} alt="" className="aside__top__icon" />
              <p className="aside__top__text">Candidates</p>
            </Link>
          </li> */}
        </ul>
      </div>
      <AuthGuard module="assessment" permission={["view", "add", "edit","delete"]}>
        <div className="aside__top__part">
          <ul className="aside__top__list">
            <li className="aside__top__item">
              <Link
                to="/recruitment/assessments"
                className="aside__top__link"
                getProps={(props) => setActive(props, "/recruitment/assessments")}
              >
                <img
                  src={homeIcon}
                  alt="Assessment home Icon"
                  className="aside__top__icon"
                />
                <p className="aside__top__text">Assessments</p>
              </Link>
            </li>
            <AuthGuard module={["assessment", "job-post"]} permission='view' option="AND">
              <AuthGuard module='assessment' permission={['add','edit','delete']}>
                <li className="aside__top__item">
                  <Link
                    to="/recruitment/manage-assignment"
                    className="aside__top__link"
                    getProps={(props) =>
                      setActive(props, "/recruitment/manage-assignment")
                    }
                  >
                    <img
                      src={fileIcon}
                      alt="Asignment Icon"
                      className="aside__top__icon"
                    />
                    <p className="aside__top__text">Assignment</p>
                  </Link>
                </li>
              </AuthGuard>
            </AuthGuard>
            <li className="aside__top__item">
              <Link
                to="/assessment/domain-assessment"
                className="aside__top__link"
                getProps={(props) =>
                  setActive(props, "/assessment/domain-assessment")
                }
              >
                <img
                  src={domainIcon}
                  alt="Domain Assessment Icon"
                  className="aside__top__icon"
                />
                <p className="aside__top__text">Domain Assessments</p>
              </Link>
            </li>
            <li className="aside__top__item">
              <Link
                to="/coding-assessments"
                className="aside__top__link"
                getProps={(props) => setActive(props, "/coding-assessments")}
              >
                <img
                  src={codingAssessment}
                  alt="Coding assessment Icon"
                  className="aside__top__icon"
                />
                <p className="aside__top__text">Coding Assessments</p>
              </Link>
            </li>
            {/* <li className="aside__top__item">
            <Link
              to="/"
              className="aside__top__link"
              getProps={(props) => setActive(props, "/")}
            >
              <img
                src={databasesIcon}
                alt="Databases Icon"
                className="aside__top__icon"
              />
              <p className="aside__top__text">Databases</p>
            </Link>
          </li> */}
            <AuthGuard module={["assessment", "job-post"]} permission='view' option="AND">
              <AuthGuard module='assessment' permission='edit'>
                <li className="aside__top__item">
                  <Link
                    to="/recruitment/review-score"
                    className="aside__top__link"
                    getProps={(props) =>
                      setActive(props, "/recruitment/review-score")
                    }
                  >
                    <img
                      src={reviewIcon}
                      alt=" Review & Score Icon"
                      className="aside__top__icon"
                    />
                    <p className="aside__top__text">Review & Score</p>
                  </Link>
                </li>
              </AuthGuard>
            </AuthGuard>
            <AuthGuard module={["assessment", "job-post"]} permission='view' option="AND">
              <li className="aside__top__item">
                <Link
                  to="/recruitment/Scoreboard"
                  className="aside__top__link"
                  getProps={(props) => setActive(props, "/recruitment/scoreboard")}
                >
                  <img
                    src={starIcon}
                    alt="Scoreboard Icon"
                    className="aside__top__icon"
                  />
                  <p className="aside__top__text">Scoreboard</p>
                </Link>
              </li>
            </AuthGuard>
          </ul>
        </div>
      </AuthGuard>
      <AuthGuard module={"job-post"} permission='view'>
        <div className="aside__top__part">
          <ul className="aside__top__list">
            <li className="aside__top__item">
              <Link
                to="/recruitment/screening-hiring"
                className="aside__top__link"
                getProps={(props) =>
                  setActive(props, "/recruitment/screening-hiring")
                }
              >
                <img
                  src={homeIcon}
                  alt="Screening & hiring Icon"
                  className="aside__top__icon"
                />
                <p className="aside__top__text">Screening & Hiring</p>
              </Link>
            </li>
            <AuthGuard module={"job-post"} permission={["view", "edit"]}>
              <li className="aside__top__item">
                <Link
                  to="/recruitment/interviews/jobs"
                  className="aside__top__link"
                  getProps={(props) =>
                    setActive(props, "/recruitment/interviews/jobs")
                  }
                >
                  <img
                    src={interviewsIcon}
                    alt="Interviews Icon"
                    className="aside__top__icon"
                  />
                  <p className="aside__top__text">Interviews</p>
                </Link>
              </li>
            </AuthGuard>
            <AuthGuard module={["job-post", "offer"]} permission='view' option="AND">
              <AuthGuard module='offer' permission={['add', 'edit', 'delete']}>
                <li className="aside__top__item">
                  <Link
                    to="/recruitment/offers"
                    className="aside__top__link"
                    getProps={(props) => setActive(props, "/recruitment/offers")}
                  >
                    <img
                      src={offerIcon}
                      alt="Offers icon"
                      className="aside__top__icon"
                    />
                    <p className="aside__top__text">Offers</p>
                  </Link>
                </li>
              </AuthGuard>
            </AuthGuard>
            <AuthGuard module={["job-post", "background"]} permission='view' option="AND">
              <AuthGuard module="background" permission={["add", "edit", "delete"]}>
                <li className="aside__top__item">
                  <Link
                    to="/recruitment/background-screening/jobs"
                    className="aside__top__link"
                    getProps={(props) =>
                      setActive(props, "/recruitment/background-screening/jobs")
                    }
                  >
                    <img
                      src={backgroundIcon}
                      alt="Background Screening Icon"
                      className="aside__top__icon"
                    />
                    <p className="aside__top__text">Background Screening</p>
                  </Link>
                </li>
              </AuthGuard>
            </AuthGuard>
            <AuthGuard module={["job-post", "drug"]} permission='view' option="AND">
              <AuthGuard module="drug" permission={["add", "edit", "delete"]}>
                <li className="aside__top__item">
                  <Link
                    to="/recruitment/drug-screening/jobs"
                    className="aside__top__link"
                    getProps={(props) =>
                      setActive(props, "/recruitment/drug-screening/jobs")
                    }
                  >
                    <img
                      src={drugScreeningIcon}
                      alt="Drug Screening Icon"
                      className="aside__top__icon"
                    />
                    <p className="aside__top__text">Drug Screening</p>
                  </Link>
                </li>
              </AuthGuard>
            </AuthGuard>
            {/* <li className="aside__top__item">
            <Link
              to="/recruitment/screening-hiring/integrations"
              className="aside__top__link"
              getProps={(props) =>
                setActive(props, "/recruitment/screening-hiring/integrations")
              }
            >
              <img
                src={integrationsIcon}
                alt="Integration Icon"
                className="aside__top__icon"
              />
              <p className="aside__top__text">Integrations</p>
            </Link>
          </li> */}
          </ul>
        </div>
      </AuthGuard>
    </div>
  );
};

export default CompanySidebar;
